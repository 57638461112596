.datepicker--cell-day.-other-month-, .datepicker--cell-year.-other-decade- {
  color: #dedede; }
  .datepicker--cell-day.-other-month-:hover, .datepicker--cell-year.-other-decade-:hover {
    color: #c5c5c5; }
  .-disabled-.-focus-.datepicker--cell-day.-other-month-, .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
    color: #dedede; }
  .-selected-.datepicker--cell-day.-other-month-, .-selected-.datepicker--cell-year.-other-decade- {
    color: #fff;
    background: #a2ddf6; }
    .-selected-.-focus-.datepicker--cell-day.-other-month-, .-selected-.-focus-.datepicker--cell-year.-other-decade- {
      background: #8ad5f4; }
  .-in-range-.datepicker--cell-day.-other-month-, .-in-range-.datepicker--cell-year.-other-decade- {
    background-color: rgba(92, 196, 239, 0.1);
    color: #cccccc; }
    .-in-range-.-focus-.datepicker--cell-day.-other-month-, .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
      background-color: rgba(92, 196, 239, 0.2); }
  .datepicker--cell-day.-other-month-:empty, .datepicker--cell-year.-other-decade-:empty {
    background: none;
    border: none; }

/* -------------------------------------------------
    Datepicker cells
   ------------------------------------------------- */
.datepicker--cells {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }

.datepicker--cell {
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 32px;
  z-index: 1; }
  .datepicker--cell.-focus- {
    background: #f0f0f0; }
  .datepicker--cell.-current- {
    color: #4EB5E6; }
    .datepicker--cell.-current-.-focus- {
      color: #4a4a4a; }
    .datepicker--cell.-current-.-in-range- {
      color: #4EB5E6; }
  .datepicker--cell.-in-range- {
    background: rgba(92, 196, 239, 0.1);
    color: #4a4a4a;
    border-radius: 0; }
    .datepicker--cell.-in-range-.-focus- {
      background-color: rgba(92, 196, 239, 0.2); }
  .datepicker--cell.-disabled- {
    cursor: default;
    color: #aeaeae; }
    .datepicker--cell.-disabled-.-focus- {
      color: #aeaeae; }
    .datepicker--cell.-disabled-.-in-range- {
      color: #a1a1a1; }
    .datepicker--cell.-disabled-.-current-.-focus- {
      color: #aeaeae; }
  .datepicker--cell.-range-from- {
    border: 1px solid rgba(92, 196, 239, 0.5);
    background-color: rgba(92, 196, 239, 0.1);
    border-radius: 4px 0 0 4px; }
  .datepicker--cell.-range-to- {
    border: 1px solid rgba(92, 196, 239, 0.5);
    background-color: rgba(92, 196, 239, 0.1);
    border-radius: 0 4px 4px 0; }
  .datepicker--cell.-range-from-.-range-to- {
    border-radius: 4px; }
  .datepicker--cell.-selected- {
    color: #fff;
    border: none;
    background: #5cc4ef; }
    .datepicker--cell.-selected-.-current- {
      color: #fff;
      background: #5cc4ef; }
    .datepicker--cell.-selected-.-focus- {
      background: #45bced; }
  .datepicker--cell:empty {
    cursor: default; }

.datepicker--days-names {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  margin: 8px 0 3px; }

.datepicker--day-name {
  color: #FF9A19;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-align: center;
  text-transform: uppercase;
  font-size: .8em; }

.datepicker--cell-day {
  width: 14.28571%; }

.datepicker--cells-months {
  height: 170px; }

.datepicker--cell-month {
  width: 33.33%;
  height: 25%; }

.datepicker--years {
  height: 170px; }

.datepicker--cells-years {
  height: 170px; }

.datepicker--cell-year {
  width: 25%;
  height: 33.33%; }

.datepicker--cell-day.-other-month-, .datepicker--cell-year.-other-decade- {
  color: #dedede; }
  .datepicker--cell-day.-other-month-:hover, .datepicker--cell-year.-other-decade-:hover {
    color: #c5c5c5; }
  .-disabled-.-focus-.datepicker--cell-day.-other-month-, .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
    color: #dedede; }
  .-selected-.datepicker--cell-day.-other-month-, .-selected-.datepicker--cell-year.-other-decade- {
    color: #fff;
    background: #a2ddf6; }
    .-selected-.-focus-.datepicker--cell-day.-other-month-, .-selected-.-focus-.datepicker--cell-year.-other-decade- {
      background: #8ad5f4; }
  .-in-range-.datepicker--cell-day.-other-month-, .-in-range-.datepicker--cell-year.-other-decade- {
    background-color: rgba(92, 196, 239, 0.1);
    color: #cccccc; }
    .-in-range-.-focus-.datepicker--cell-day.-other-month-, .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
      background-color: rgba(92, 196, 239, 0.2); }
  .datepicker--cell-day.-other-month-:empty, .datepicker--cell-year.-other-decade-:empty {
    background: none;
    border: none; }

/* -------------------------------------------------
    Datepicker
   ------------------------------------------------- */
.datepickers-container {
  position: absolute;
  left: 0;
  top: 0; }
  @media print {
    .datepickers-container {
      display: none; } }

.datepicker {
  background: #fff;
  border: 1px solid #dbdbdb;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-sizing: content-box;
  font-family: Tahoma, sans-serif;
  font-size: 14px;
  color: #4a4a4a;
  width: 250px;
  position: absolute;
  left: -100000px;
  opacity: 0;
  transition: opacity 0.3s ease, left 0s 0.3s, -webkit-transform 0.3s ease;
  transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0.3s;
  transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0.3s, -webkit-transform 0.3s ease;
  z-index: 100; }
  .datepicker.-from-top- {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px); }
  .datepicker.-from-right- {
    -webkit-transform: translateX(8px);
            transform: translateX(8px); }
  .datepicker.-from-bottom- {
    -webkit-transform: translateY(8px);
            transform: translateY(8px); }
  .datepicker.-from-left- {
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px); }
  .datepicker.active {
    opacity: 1;
    -webkit-transform: translate(0);
            transform: translate(0);
    transition: opacity 0.3s ease, left 0s 0s, -webkit-transform 0.3s ease;
    transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0s;
    transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0s, -webkit-transform 0.3s ease; }

.datepicker-inline .datepicker {
  border-color: #d7d7d7;
  box-shadow: none;
  position: static;
  left: auto;
  right: auto;
  opacity: 1;
  -webkit-transform: none;
          transform: none; }

.datepicker-inline .datepicker--pointer {
  display: none; }

.datepicker--content {
  box-sizing: content-box;
  padding: 4px; }
  .-only-timepicker- .datepicker--content {
    display: none; }

.datepicker--pointer {
  position: absolute;
  background: #fff;
  border-top: 1px solid #dbdbdb;
  border-right: 1px solid #dbdbdb;
  width: 10px;
  height: 10px;
  z-index: -1; }
  .-top-left- .datepicker--pointer, .-top-center- .datepicker--pointer, .-top-right- .datepicker--pointer {
    top: calc(100% - 4px);
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }
  .-right-top- .datepicker--pointer, .-right-center- .datepicker--pointer, .-right-bottom- .datepicker--pointer {
    right: calc(100% - 4px);
    -webkit-transform: rotate(225deg);
            transform: rotate(225deg); }
  .-bottom-left- .datepicker--pointer, .-bottom-center- .datepicker--pointer, .-bottom-right- .datepicker--pointer {
    bottom: calc(100% - 4px);
    -webkit-transform: rotate(315deg);
            transform: rotate(315deg); }
  .-left-top- .datepicker--pointer, .-left-center- .datepicker--pointer, .-left-bottom- .datepicker--pointer {
    left: calc(100% - 4px);
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .-top-left- .datepicker--pointer, .-bottom-left- .datepicker--pointer {
    left: 10px; }
  .-top-right- .datepicker--pointer, .-bottom-right- .datepicker--pointer {
    right: 10px; }
  .-top-center- .datepicker--pointer, .-bottom-center- .datepicker--pointer {
    left: calc(50% - 10px / 2); }
  .-left-top- .datepicker--pointer, .-right-top- .datepicker--pointer {
    top: 10px; }
  .-left-bottom- .datepicker--pointer, .-right-bottom- .datepicker--pointer {
    bottom: 10px; }
  .-left-center- .datepicker--pointer, .-right-center- .datepicker--pointer {
    top: calc(50% - 10px / 2); }

.datepicker--body {
  display: none; }
  .datepicker--body.active {
    display: block; }

.datepicker--cell-day.-other-month-, .datepicker--cell-year.-other-decade- {
  color: #dedede; }
  .datepicker--cell-day.-other-month-:hover, .datepicker--cell-year.-other-decade-:hover {
    color: #c5c5c5; }
  .-disabled-.-focus-.datepicker--cell-day.-other-month-, .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
    color: #dedede; }
  .-selected-.datepicker--cell-day.-other-month-, .-selected-.datepicker--cell-year.-other-decade- {
    color: #fff;
    background: #a2ddf6; }
    .-selected-.-focus-.datepicker--cell-day.-other-month-, .-selected-.-focus-.datepicker--cell-year.-other-decade- {
      background: #8ad5f4; }
  .-in-range-.datepicker--cell-day.-other-month-, .-in-range-.datepicker--cell-year.-other-decade- {
    background-color: rgba(92, 196, 239, 0.1);
    color: #cccccc; }
    .-in-range-.-focus-.datepicker--cell-day.-other-month-, .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
      background-color: rgba(92, 196, 239, 0.2); }
  .datepicker--cell-day.-other-month-:empty, .datepicker--cell-year.-other-decade-:empty {
    background: none;
    border: none; }

/* -------------------------------------------------
    Navigation
   ------------------------------------------------- */
.datepicker--nav {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-bottom: 1px solid #efefef;
  min-height: 32px;
  padding: 4px; }
  .-only-timepicker- .datepicker--nav {
    display: none; }

.datepicker--nav-title,
.datepicker--nav-action {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center; }

.datepicker--nav-action {
  width: 32px;
  border-radius: 4px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .datepicker--nav-action:hover {
    background: #f0f0f0; }
  .datepicker--nav-action.-disabled- {
    visibility: hidden; }
  .datepicker--nav-action svg {
    width: 32px;
    height: 32px; }
  .datepicker--nav-action path {
    fill: none;
    stroke: #9c9c9c;
    stroke-width: 2px; }

.datepicker--nav-title {
  border-radius: 4px;
  padding: 0 8px; }
  .datepicker--nav-title i {
    font-style: normal;
    color: #9c9c9c;
    margin-left: 5px; }
  .datepicker--nav-title:hover {
    background: #f0f0f0; }
  .datepicker--nav-title.-disabled- {
    cursor: default;
    background: none; }

.datepicker--buttons {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 4px;
  border-top: 1px solid #efefef; }

.datepicker--button {
  color: #4EB5E6;
  cursor: pointer;
  border-radius: 4px;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  height: 32px; }
  .datepicker--button:hover {
    color: #4a4a4a;
    background: #f0f0f0; }

.datepicker--cell-day.-other-month-, .datepicker--cell-year.-other-decade- {
  color: #dedede; }
  .datepicker--cell-day.-other-month-:hover, .datepicker--cell-year.-other-decade-:hover {
    color: #c5c5c5; }
  .-disabled-.-focus-.datepicker--cell-day.-other-month-, .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
    color: #dedede; }
  .-selected-.datepicker--cell-day.-other-month-, .-selected-.datepicker--cell-year.-other-decade- {
    color: #fff;
    background: #a2ddf6; }
    .-selected-.-focus-.datepicker--cell-day.-other-month-, .-selected-.-focus-.datepicker--cell-year.-other-decade- {
      background: #8ad5f4; }
  .-in-range-.datepicker--cell-day.-other-month-, .-in-range-.datepicker--cell-year.-other-decade- {
    background-color: rgba(92, 196, 239, 0.1);
    color: #cccccc; }
    .-in-range-.-focus-.datepicker--cell-day.-other-month-, .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
      background-color: rgba(92, 196, 239, 0.2); }
  .datepicker--cell-day.-other-month-:empty, .datepicker--cell-year.-other-decade-:empty {
    background: none;
    border: none; }

/* -------------------------------------------------
    Timepicker
   ------------------------------------------------- */
.datepicker--time {
  border-top: 1px solid #efefef;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 4px;
  position: relative; }
  .datepicker--time.-am-pm- .datepicker--time-sliders {
    -webkit-flex: 0 1 138px;
        -ms-flex: 0 1 138px;
            flex: 0 1 138px;
    max-width: 138px; }
  .-only-timepicker- .datepicker--time {
    border-top: none; }

.datepicker--time-sliders {
  -webkit-flex: 0 1 153px;
      -ms-flex: 0 1 153px;
          flex: 0 1 153px;
  margin-right: 10px;
  max-width: 153px; }

.datepicker--time-label {
  display: none;
  font-size: 12px; }

.datepicker--time-current {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  font-size: 14px;
  text-align: center;
  margin: 0 0 0 10px; }

.datepicker--time-current-colon {
  margin: 0 2px 3px;
  line-height: 1; }

.datepicker--time-current-hours,
.datepicker--time-current-minutes {
  line-height: 1;
  font-size: 19px;
  font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
  position: relative;
  z-index: 1; }
  .datepicker--time-current-hours:after,
  .datepicker--time-current-minutes:after {
    content: '';
    background: #f0f0f0;
    border-radius: 4px;
    position: absolute;
    left: -2px;
    top: -3px;
    right: -2px;
    bottom: -2px;
    z-index: -1;
    opacity: 0; }
  .datepicker--time-current-hours.-focus-:after,
  .datepicker--time-current-minutes.-focus-:after {
    opacity: 1; }

.datepicker--time-current-ampm {
  text-transform: uppercase;
  -webkit-align-self: flex-end;
      -ms-flex-item-align: end;
          align-self: flex-end;
  color: #9c9c9c;
  margin-left: 6px;
  font-size: 11px;
  margin-bottom: 1px; }

.datepicker--time-row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 11px;
  height: 17px;
  background: linear-gradient(to right, #dedede, #dedede) left 50%/100% 1px no-repeat; }
  .datepicker--time-row:first-child {
    margin-bottom: 4px; }
  .datepicker--time-row input[type='range'] {
    background: none;
    cursor: pointer;
    -webkit-flex: 1;
        -ms-flex: 1;
            flex: 1;
    height: 100%;
    padding: 0;
    margin: 0;
    -webkit-appearance: none; }
    .datepicker--time-row input[type='range']::-webkit-slider-thumb {
      -webkit-appearance: none; }
    .datepicker--time-row input[type='range']::-ms-tooltip {
      display: none; }
    .datepicker--time-row input[type='range']:hover::-webkit-slider-thumb {
      border-color: #b8b8b8; }
    .datepicker--time-row input[type='range']:hover::-moz-range-thumb {
      border-color: #b8b8b8; }
    .datepicker--time-row input[type='range']:hover::-ms-thumb {
      border-color: #b8b8b8; }
    .datepicker--time-row input[type='range']:focus {
      outline: none; }
      .datepicker--time-row input[type='range']:focus::-webkit-slider-thumb {
        background: #5cc4ef;
        border-color: #5cc4ef; }
      .datepicker--time-row input[type='range']:focus::-moz-range-thumb {
        background: #5cc4ef;
        border-color: #5cc4ef; }
      .datepicker--time-row input[type='range']:focus::-ms-thumb {
        background: #5cc4ef;
        border-color: #5cc4ef; }
    .datepicker--time-row input[type='range']::-webkit-slider-thumb {
      box-sizing: border-box;
      height: 12px;
      width: 12px;
      border-radius: 3px;
      border: 1px solid #dedede;
      background: #fff;
      cursor: pointer;
      transition: background .2s; }
    .datepicker--time-row input[type='range']::-moz-range-thumb {
      box-sizing: border-box;
      height: 12px;
      width: 12px;
      border-radius: 3px;
      border: 1px solid #dedede;
      background: #fff;
      cursor: pointer;
      transition: background .2s; }
    .datepicker--time-row input[type='range']::-ms-thumb {
      box-sizing: border-box;
      height: 12px;
      width: 12px;
      border-radius: 3px;
      border: 1px solid #dedede;
      background: #fff;
      cursor: pointer;
      transition: background .2s; }
    .datepicker--time-row input[type='range']::-webkit-slider-thumb {
      margin-top: -6px; }
    .datepicker--time-row input[type='range']::-webkit-slider-runnable-track {
      border: none;
      height: 1px;
      cursor: pointer;
      color: transparent;
      background: transparent; }
    .datepicker--time-row input[type='range']::-moz-range-track {
      border: none;
      height: 1px;
      cursor: pointer;
      color: transparent;
      background: transparent; }
    .datepicker--time-row input[type='range']::-ms-track {
      border: none;
      height: 1px;
      cursor: pointer;
      color: transparent;
      background: transparent; }
    .datepicker--time-row input[type='range']::-ms-fill-lower {
      background: transparent; }
    .datepicker--time-row input[type='range']::-ms-fill-upper {
      background: transparent; }
  .datepicker--time-row span {
    padding: 0 12px; }

.datepicker--time-icon {
  color: #9c9c9c;
  border: 1px solid;
  border-radius: 50%;
  font-size: 16px;
  position: relative;
  margin: 0 5px -1px 0;
  width: 1em;
  height: 1em; }
  .datepicker--time-icon:after, .datepicker--time-icon:before {
    content: '';
    background: currentColor;
    position: absolute; }
  .datepicker--time-icon:after {
    height: .4em;
    width: 1px;
    left: calc(50% - 1px);
    top: calc(50% + 1px);
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); }
  .datepicker--time-icon:before {
    width: .4em;
    height: 1px;
    top: calc(50% + 1px);
    left: calc(50% - 1px); }

.datepicker--cell-day.-other-month-, .datepicker--cell-year.-other-decade- {
  color: #dedede; }
  .datepicker--cell-day.-other-month-:hover, .datepicker--cell-year.-other-decade-:hover {
    color: #c5c5c5; }
  .-disabled-.-focus-.datepicker--cell-day.-other-month-, .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
    color: #dedede; }
  .-selected-.datepicker--cell-day.-other-month-, .-selected-.datepicker--cell-year.-other-decade- {
    color: #fff;
    background: #a2ddf6; }
    .-selected-.-focus-.datepicker--cell-day.-other-month-, .-selected-.-focus-.datepicker--cell-year.-other-decade- {
      background: #8ad5f4; }
  .-in-range-.datepicker--cell-day.-other-month-, .-in-range-.datepicker--cell-year.-other-decade- {
    background-color: rgba(92, 196, 239, 0.1);
    color: #cccccc; }
    .-in-range-.-focus-.datepicker--cell-day.-other-month-, .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
      background-color: rgba(92, 196, 239, 0.2); }
  .datepicker--cell-day.-other-month-:empty, .datepicker--cell-year.-other-decade-:empty {
    background: none;
    border: none; }

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 15px;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  padding: 15px 0;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }
  .mfp-preloader a {
    color: #CCC; }
    .mfp-preloader a:hover {
      color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  display: none !important;
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }
  .mfp-close:hover,
  .mfp-close:focus {
    opacity: 1; }
  .mfp-close:active {
    top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }
  .mfp-arrow:active {
    margin-top: -54px; }
  .mfp-arrow:hover,
  .mfp-arrow:focus {
    opacity: 1; }
  .mfp-arrow:before,
  .mfp-arrow:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }
  .mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
  .mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }
  .mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px; }
  .mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }
  .mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px; }
  .mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px; }
  .mfp-iframe-holder .mfp-close {
    top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }
  .mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444; }
  .mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px; }
  .mfp-figure figure {
    margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
 }

 /* overlay at start */
.mfp-fade.mfp-bg {
  opacity: 0;

  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}
/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;

  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}
/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}